import { FeeSorted } from '@/models/fee';
import axios from 'axios';

class FeeService {
  public async getFees(): Promise<FeeSorted[]> {
    return (await axios.get<{ data: FeeSorted[] }>('/fee')).data.data;
  }
}

export default new FeeService();
