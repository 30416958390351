






import { Component, Vue } from 'vue-property-decorator';
import PriceList from '@/components/pricelist.vue';
import { metaInfo } from '@/utils/seo/metaInfo';
import { buildUrl } from '@/utils/url';

@Component({
  components: {
    PriceList
  },
  metaInfo: metaInfo({
    title: 'Ceník | Kakadoo',
    url: buildUrl('/cenik'),
    description: 'Ceny dodávek již od 590 Kč/den. Žádnými skrytými poplatky vás nepřekvapíme.'
  })
})
export default class PriceListPage extends Vue {}
